/*!
    * Theme scripts 
    * @company Reactive Development LLC
    * @author Jeremy Selph <jselph@reactivedevelopment.net>
    * @link http://www.reactivedevelopment.net
    * @version 0.2 [ ver 0.1 is template file ]
*/ /*
        * html 5 boilerplate javascript for testing in chrome and firebug console
        * make sure to comment out when u go live
        * Avoid `console` errors in browsers that lack a console.
        * @since 0.1
    */
    (function() {
        
        var method;
        var noop = function () {};
        var methods = [
            'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
            'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
            'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
            'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
        ];
        
        var length = methods.length;
        var console = (window.console = window.console || {});

        while (length--) {
            
            method = methods[length];

            /**             
                * Only stub undefined methods.
                * @since 0.1
            */
            if ( !console[method] ) { console[method] = noop; }

        }

    }());

    /**             
        * javascript constant variables we may uses in the following classes
        * @author Jeremy Selph <jselph@reactivedevelopment.net>
        * @since 0.1
    */
    var isMobile, oldBrowser, isiPad, isAndroid, isIE9, whichBrowser, userLoggedIn, lightboxOpen, isHome,

        host            = window.location.host,
        daBrowser       = navigator.userAgent,
        
        homeUrl         = my_vars.site_url,
        ajaxUrl         = my_vars.ajax_url,
        uploadsDir      = my_vars.uploads_url,
        pluginsDir      = my_vars.plugins_url,
        templateDir     = my_vars.theme_url,
        templateImgDir  = my_vars.theme_url + 'img/',
        templateCSSDir  = my_vars.theme_url + 'css/',
        templateJSDir   = my_vars.theme_url + 'js/',

        /**             
            * Set our mobile width param. This is how we know to show the mobile menu and other items.
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @version 0.2
            * @since 0.1
        */  
        mobileWidth     = 920;

    /**             
        * jquery main browser function
        * @author Jeremy Selph <jselph@reactivedevelopment.net>
        * @uses jquery.min.js
        * @since 0.1
    */
    jQuery(function($) {

        /**             
            * check if mobile and set our browser vars
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @since 0.1
        */  
        rd_checkBrowser();

        /**             
            * second check based on device width and as the screen width chnages (only if isMobile == false)
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @since 0.1
        */ 
        if ( !isMobile ){

            $( window ).bind( "resize", function() {

                if ( $( window ).width() < mobileWidth ){ isMobile = true; rd_screen_width_changed( isMobile ); }
                else { isMobile = false; rd_screen_width_changed( isMobile ); }

            }).trigger( "resize" );

        }

    });

    /**             
        * jquery onload
        * @author Jeremy Selph <jselph@reactivedevelopment.net>
        * @uses jquery.min.js
        * @since 0.1
    */
    jQuery(function($) {

        /**             
            * main nav superfish menu
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.supersubs.js, jquery.superfish.js
            * @since 0.1
        */
        $( "ul.sf-menu" ).supersubs({ 

            minWidth        :   15,     // minimum width of sub-menus in em units 
            maxWidth        :   20,     // maximum width of sub-menus in em units 
            extraWidth      :   1       // extra width can ensure lines don't sometimes turn over

        }).superfish({ 

            autoArrows      :   false,  // disable generation of arrow mark-up 
            delay           :   200,    // the delay in milliseconds that the mouse can remain outside a submenu without it closing 
            animation       :   { height: "show" },

        });

        /**             
            * fancybox 2
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.fancybox.pack.js, jquery.fancybox-media.js
            * @since 0.1
        */
        $( ".lightbox" ).fancybox({

            padding         :   20,
            margin          :   40,
            wrapCSS         :   'jsLightBoxCont',

            beforeLoad      :   function( current, previous ) {

                lightboxOpen = true;
                if ( isMobile ){

                    console.log( "FANCYBOX: we are mobile or mobile in width" );
                    jQuery.extend(this, {
                        
                        wrapCSS         :   'jsMobileLightbox',
                        aspectRatio     :   false,
                        padding         :   20,
                        margin          :   0,
                        type            :   'html',
                        width           :   '100%',
                        height          :   '100%',
                        content         :   '<div class="fancybox-image"><img src="' + this.href + '" /></div>'
                    
                    });

                }
                
            },

            afterClose      :   function( current, previous ) { lightboxOpen = false; }

        });

        /**             
            * jquery meanMenu
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.meanmenu.min.js
            * @since 0.1
        */
        $( "#menuCont" ).meanmenu({ 

            meanScreenWidth     : mobileWidth, 
            meanRemoveAttrs     : ".sf-menu"

        });

        /**
            * jquery cycle  height fix
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.cycle2.min.js
            * @since 0.1
        */ 
        $( ".cycle-slideshow" )
            .not( "#productsSlide, #product_carousel" )
            .on( "cycle-before", function(
                event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag ) {
            
            $( this ).css( "height", $( incomingSlideEl ).height() );

        });

        /**
            * jquery cycle next and prev [ main slider ]
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.cycle2.min.js
            * @since 0.1
        */ 
        $( ".slideArrowBtn.prev" ).click(function() { $( "#slider" ).cycle( "prev" ); return false; });
        $( ".slideArrowBtn.next" ).click(function() { $( "#slider" ).cycle( "next" ); return false; });

        /**
            * jquery cycle next and prev [ main slider ]
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.cycle2.min.js
            * @since 0.1
        */ 
        $( ".testSlideBtn" ).click(function() {

            //$( ".testSlideBtn.selected" ).removeClass( "selected" );
            var slide = parseInt( $( this ).attr( "href" ) );
            $( "#test_slider" ).cycle( "goto", slide );
            //$( this ).addClass( "selected" );
            return false;

        });

        /**
            * jquery cycle next and prev [ main slider ]
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.cycle2.min.js
            * @since 0.1
        */ 
        $( "#test_slider" ).on( "cycle-before", function( event, optionHash, outgoingSlideEl, incomingSlideEl, forwardFlag ) {
            
            var slideID = $( incomingSlideEl ).attr( "id" );
            var idsplit = slideID.split( "_" );
            
            $( "a.testSlideBtn.selected" ).removeClass( "selected" );
            $( "a#test_button_" + idsplit[1] + "" ).addClass( "selected" );

            console.log( "a#test_button_" + idsplit[1] + "" );

        });

        /**             
            * after gfomrs is submitted trigger uniform again please
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses gravityforms.min.js
            * @since 0.1
        */
        gforms_stuff();
        $( document ).bind( 'gform_post_render', function(){

            gforms_stuff();

        });

        /**             
            * video mbile
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.fitvids.js
            * @since 0.1
        */            
        $( "body" ).fitVids();

        /**             
            * footer map
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, gmaps.js
            * @since 0.1
        */
        var map = new GMaps({ 

            div: '#foot_map_image', 
            lat: 40.345111, 
            lng: -74.133042

        }); map.addMarker({

            lat: 40.345111, 
            lng: -74.133042,
            click: function(e) {
                
                var win = window.open(

                    "https://www.google.com/maps/dir//1409+W+Front+St,+Middletown,+NJ+07738/@40.3451151,-74.133042,17z/data=!4m16!1m7!3m6!1s0x89c232041a424897:0x7a17004e35ab6697!2s1409+W+Front+St,+Middletown,+NJ+07738!3b1!8m2!3d40.345111!4d-74.130848!4m7!1m0!1m5!1m1!1s0x89c232041a424897:0x7a17004e35ab6697!2m2!1d-74.130848!2d40.345111", 
                    "_blank"

                ); win.focus();

            }
        
        });

    });

    /**             
        * Trigger when our width of the screen has chnaged.
        * @package rd_screen_width_changed
        * @author Jeremy Selph <jselph@reactivedevelopment.net>
        * @uses jquery.min.js, jquery.fancybox.pack.js
        * @since 0.1
    */
    function rd_screen_width_changed( mobile ){

        if ( mobile ){

            /**             
                * close lightbox if open
                * @author Jeremy Selph <jselph@reactivedevelopment.net>
                * @uses jquery.min.js, jquery.fancybox.pack.js
                * @since 0.1
            */
            if ( lightboxOpen ){ $.fancybox.close(); }

            /**             
                * add mobile class
                * @author Jeremy Selph <jselph@reactivedevelopment.net>
                * @since 0.1
            */
            $( "body" ).addClass( "mobile" );

            /**             
                * set our variable
                * @author Jeremy Selph <jselph@reactivedevelopment.net>
                * @since 0.1
            */
            isMobile = true;

        } else {

            /**             
                * close lightbox if open
                * @author Jeremy Selph <jselph@reactivedevelopment.net>
                * @uses jquery.min.js, jquery.fancybox.pack.js
                * @since 0.2
            */
            if ( lightboxOpen ){ $.fancybox.close(); }

            /**             
                * remove mobile class
                * @author Jeremy Selph <jselph@reactivedevelopment.net>
                * @since 0.1
            */
            $( "body" ).removeClass( "mobile" );

            /**             
                * set our variable
                * @author Jeremy Selph <jselph@reactivedevelopment.net>
                * @since 0.1
            */
            isMobile = false;

        }

    }

    /**             
        * check if we are mobile or not
        * @package rd_checkBrowser
        * @author Jeremy Selph <jselph@reactivedevelopment.net>
        * @uses jquery.min.js, jquery.browser.min.js
        * @since 0.1
    */
    function rd_checkBrowser(){

        var ua = daBrowser.toLowerCase();

        /**             
            * Basics
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @since 0.1
        */ 
        $( ".no-js" ).removeClass( "no-js" );
        if ( $( "body" ).hasClass( "home" ) ){ isHome = true; }

        /**             
            * first check based on device
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.browser.min.js
            * @since 0.1
        */
        if ( $.browser.mobile ){ isMobile = true; $( "body" ).addClass( "mobile" ); } else { isMobile = false; }

        /**             
            * check if this devive is an ipad
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @since 0.1
        */
            isiPad = daBrowser.match(/iPad/i) != null;
        if( isiPad ){ isMobile = true; $( "body" ).addClass( "ipad" ); } else { isMobile = false; }

        /**             
            * check if this device is an andriod
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @since 0.1
        */
            isAndroid = ua.indexOf("android") > -1;
        if( isAndroid ){ isMobile = true; $( "body" ).addClass( "android" ); } else { isMobile = false; }

        /**             
            * get browser version
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.browser.min.js
            * @since 0.1
        */
        if ( $.browser.msie || daBrowser.indexOf("Trident") > -1 ){ whichBrowser = "msie"; }
        else if ( $.browser.chrome ){ whichBrowser = "chrome"; }
        else if ( $.browser.mozilla ){ whichBrowser = "mozilla"; }
        else if ( $.browser.opera ){ whichBrowser = "opera"; }
        else { whichBrowser = "other"; } $( "body" ).addClass( whichBrowser );

        /**             
            * check for older browsers
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.browser.min.js
            * @since 0.1
        */
        if ( $( "html" ).hasClass( "oldie" ) ){ oldBrowser = true; $( "body" ).addClass( "oldie" ); }
        else if ( $.browser.chrome && $.browser.version < 19 ){ oldBrowser = true; $( "body" ).addClass( "oldie" ); }
        else if ( $.browser.mozilla && $.browser.version < 9 ){ oldBrowser = true; $( "body" ).addClass( "oldie" ); }
        else if ( $.browser.opera && $.browser.version < 12 ){ oldBrowser = true; $( "body" ).addClass( "oldie" ); }
        else { oldBrowser = false; $( "body" ).removeClass( "oldie" ); }

        /**             
            * check for ie9
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @uses jquery.min.js, jquery.browser.min.js
            * @since 0.1
        */
        if ( $( "body" ).hasClass( "ie9" ) || 
         
            ( ( $.browser.msie || daBrowser.indexOf("Trident") > -1 ) && 
              ( $.browser.version < 10 && $.browser.version >= 9 ) )
         
         ){ isIE9 = true; } else { isIE9 = false; }

        /**             
            * checked to see if users logged in
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @since 0.1
        */
        if ( $( "body" ).hasClass( "logged-in" ) ){ userLoggedIn = true; }

        /**             
            * if we are mobile then set the class
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @since 0.1
        */
        if ( isMobile ){ $( "body" ).addClass( "mobile" ); } else { $( "body" ).removeClass( "mobile" ); }

    }

    /**             
        * function update google with each ajax load
        * @package rd_update_google_analytics
        * @author Jeremy Selph <jselph@reactivedevelopment.net>
        * @uses analytics.js
        * @since 0.1
    */
    function rd_update_google_analytics( daUrl, title ){

        __gaTracker( "send", "pageview", { "page" : daUrl.replace( homeUrl, "" ), "title" : title } );

    }

    /**             
        * scroll to function
        * @package rd_scroll_to
        * @author Jeremy Selph <jselph@reactivedevelopment.net>
        * @uses jquery.min.js
        * @since 0.1
    */
    function rd_scroll_to( div ){

        $( "html, body" ).animate({ scrollTop: $( div ).offset().top }, 500);

    }

    /**             
        * gforms
        * @package gforms_stuff
        * @author Jeremy Selph <jselph@reactivedevelopment.net>
        * @uses jquery.min.js
        * @since 0.2
    */
    function gforms_stuff(){

        /**             
            * on field hovers show and hide defualt values
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @since 0.2
        */
        $( "#s" )
            .focusin( function(){

                if ( $( this ).val() == "Search" ){ $( this ).val( "" ); }
            
            }
        )
            .focusout( function(){

                if ( $( this ).val() == "" ){ $( this ).val( "Search" ); }
                
            }
        );

        /**             
            * add select styles to our SELECT elements
            * @author Jeremy Selph <jselph@reactivedevelopment.net>
            * @since 0.2
        */
        $( ".gform_body select" ).SumoSelect({ placeholder: "Select..." });

    }

    /**             
        * custom chechkbox function
        * @package customCheckbox
        * @author Jeremy Selph <jselph@reactivedevelopment.net>
        * @uses jquery.min.js
        * @link http://www.tutorialrepublic.com/faq/how-to-create-custom-checkboxes-using-css-and-jquery.php
        * @since 0.2
    */
    function customCheckbox( checkboxName ){
        
        var checkBox = $( checkboxName );
        $( checkBox ).each(function(){
            
            if ( $( this ).parent( "span.custom-checkbox" ).length == 0 ){
                $( this ).wrap( "<span class='custom-checkbox'></span>" );
            }
            
            if( $( this ).is( ":checked" ) ){ 

                $( this ).parent().addClass( "selected" );

            }
        
        });

        $( checkBox ).change(function(){ 

            $( this ).parent().toggleClass( "selected" );

        });

    }